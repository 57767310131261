<template>
  <div>
    <b-overlay :show="isLoading" spinner-variant="primary">
      <b-card>
        <FullCalendar ref="fullCalendar" :options="calendarOptions" />
      </b-card>
      <b-modal hide-footer hide-header centered v-model="showEventPopup">
        <div v-if="currentEvent.extendedProps" class="text-center">
          <h1 class="text-center">
            Запис на прийом до
            <strong class="d-block">
              {{ currentEvent.extendedProps.name }}</strong
            >
          </h1>
          <h2 class="mt-3">
            {{
              $moment(currentEvent.extendedProps.created).format(
                "dddd DD MMMM YYYY"
              )
            }}
          </h2>
          <h1>
            <strong>{{
              $moment(currentEvent.extendedProps.created).format("HH:ss")
            }}</strong>
          </h1>
          <div class="modal-cta mt-3">
            <b-button
              v-if="!currentEvent.extendedProps.canceled"
              class="d-block w-100 mt-2"
              variant="danger"
              @click="initCalcelation()"
            >
              Скасувати запис
            </b-button>
            <b-alert
              class="d-block w-100 mt-2 py-2"
              variant="danger"
              :show="currentEvent.extendedProps.canceled"
            >
              Запис скасовано
            </b-alert>
            <b-button
              @click="showEventPopup = false"
              class="d-block w-100 mt-1"
              variant="outline-primary"
            >
              Закрити
            </b-button>
          </div>
        </div>
      </b-modal>
      <b-modal hide-footer hide-header centered v-model="showCancelPopup">
        <b-overlay :show="cancelLoading" spinner-variant="primary">
          <h1 class="mt-2 mb-4">Справді скасувати запис?</h1>
          <b-form-textarea
            v-model="cancel_note"
            placeholder="Коментар до скасування"
          >
          </b-form-textarea>
          <b-button
            class="d-block w-100 mt-2"
            variant="danger"
            :disabled="cancel_note.length < 5"
            @click="cancelEvent()"
          >
            Скасувати запис
          </b-button>
        </b-overlay>
      </b-modal>
    </b-overlay>
  </div>
</template>

<script>
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
export default {
  components: {
    FullCalendar,
  },
  data() {
    return {
      isLoading: false,
      cancelLoading: false,
      showEventPopup: false,
      showCancelPopup: false,
      cancel_note: "",
      currentEvent: {},
      appontments: [],
      calendarOptions: {
        eventClick: (event) => {
          this.eventClick(event);
        },
        eventMouseEnter: (event) => {
          this.eventHover(event);
        },
        selectable: true,
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
        noEventsText: "Немає подій для відображення",
        todayButtonText: "Сьогодні",
        allDayText: "Увесь день",
        initialView: "dayGridMonth",
        locale: "uk",
        headerToolbar: {
          left: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
          center: "title",
          right: "today,prev,next",
        },
        events: [],
        week: {
          dow: 1,
          doy: 7,
        },
        buttonText: {
          today: "Сьогодні",
          month: "Місяць",
          week: "Тиждень",
          day: "День",
          list: "Список",
        },
        views: {
          dayGridMonth: { buttonText: "Місяць" },
          timeGridWeek: { buttonText: "Тиждень" },
          timeGridDay: { buttonText: "День" },
          listWeek: { buttonText: "Список" },
        },
      },
    };
  },
  methods: {
    eventClick(info) {
      this.currentEvent = info.event;
      this.showEventPopup = true;
    },
    eventHover() {},
    async getAppointments() {
      this.isLoading = true;
      let workerDepartment = Number(this.$store.state.workerDepartment);
      console.log(workerDepartment);
      let args = {
        collectionName: "appointments",
        where: ["department", "==", workerDepartment],
      };
      let result = await this.$db.getCollection(args);
      if (result.docs) {
        this.appontments = result.docs;
      }
      this.addEvents();
      this.isLoading = false;
    },
    addEvents() {
      for (var i = 0; i < this.appontments.length; i++) {
        let item = this.appontments[i];
        let color = item.canceled ? "#f00" : "#16a250";
        let bg = "#fceaeb";
        let end = this.$moment(item.date_time)
          .add(15, "minutes")
          .toDate();
        let event = {
          title:
            "Запис " + this.$moment(item.date_time.toDate()).format("HH:ss"),
          all_day: false,
          has_end: true,
          created: item.date_time.toDate(),
          created_by: item.created_by,
          department: item.department,
          name: item.worker_name,
          start: item.date_time.toDate(),
          end: end,
          uid: item.uid,
          canceled: item.canceled,
          color: color,
        };
        this.$refs.fullCalendar.getApi().addEvent(event);
      }
    },
    async cancelEvent() {
      this.cancelLoading = true;
      let uid = this.currentEvent.extendedProps.uid;
      let data = {
        canceled: true,
        note: this.cancel_note,
      };
      await this.$db.update("appointments", uid, data);

      this.getAppointments();
      this.showCancelPopup = false;
      this.showEventPopup = false;
      this.cancelLoading = false;
    },
    initCalcelation() {
      this.showCancelPopup = true;
    },
    async getCitizen(uid) {
      let name = "";
      let data = false;
      if (uid) {
        data = await this.$db.getDocument(uid);
        if (data) {
          name = data.first_name;
        }
      }

      return name;
    },
  },
  mounted() {
    this.getAppointments();
  },
};
</script>

<style lang="scss" scoped></style>
